import { Box, Container, Typography } from "@mui/material";
import React from "react";

const PercentComponent = ({ number, sign, before, after }) => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="Heading" sx={{ letterSpacing: "0.01em",fontWeight:400 }}>
          {number} {sign}
        </Typography>

        <Typography
          sx={{ fontSize: { md: "18px", xs: "12px" }, letterSpacing: "0.01em" }}
        >
          {before} {after}
        </Typography>
      </Box>
    </Container>
  );
};

export default PercentComponent;
