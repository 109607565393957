import { Box, Container, Typography } from "@mui/material";
import React from "react";

const SkillItem = ({ img, title, percentage }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center", // Center the items horizontally
        alignItems: "center", // Center the items vertically
        alignContent: "center",
      }}
    >
      <Container maxWidth="xs">
        <Box
          sx={{
            backgroundColor: "#353535",
            p: 2,
            borderRadius: "50%",
            width: "100px", // Fixed width
            height: "100px", // Fixed height
            display: "flex", // Use flex to align the content
            flexDirection: "column", // Align items vertically
            justifyContent: "center", // Center vertically within the circle
            alignItems: "center", // Center horizontally within the circle
            textAlign: "center", // Center the text
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Optional shadow for better visual appearance
          }}
        >
          <Box
            component="img"
            src={img}
            sx={{
              maxWidth: "50px", // Control the image size inside the circle
              maxHeight: "50px", // Maintain a consistent size for the image
              objectFit: "contain", // Maintain aspect ratio of the image
            }}
          />
          <Typography sx={{ py: 1, fontWeight: "bold", color: "#fff" }}>
            {title}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: "#ff6347", // Changed to a consistent orange-red color
            }}
          >
            {percentage}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default SkillItem;
