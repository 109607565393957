import React from "react";
import { Box, Paper, Typography, Chip, Container } from "@mui/material";
import { NavLink } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const FeatureCard = ({
  imgSrc,
  imgAlt,
  title,
  categories,
  description,
  to,
}) => {
  return (
    <Container maxWidth="xs">
      <NavLink to={to} style={{ textDecoration: "none" }}>
        <Paper
          elevation={7}
          sx={{
            backgroundColor: "#151515",
            p: 2,
            borderRadius: "20px",
            my: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            position: "relative",
            transition: "background-color 0.4s ease, transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)", // Slight zoom on hover
              background: "linear-gradient(90deg, #000, #004aad)", // Gradient effect
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "flex-start",
              flexWrap: "wrap",
              position: "absolute",
              p: 1,
              zIndex: 1,
            }}
          >
            {categories.map((category) => (
              <Chip
                key={category}
                label={category}
                sx={{
                  backgroundColor: "#ffbd59",
                  borderTopLeftRadius: "80px",
                  borderBottomRightRadius: "80px",
                  color: "#000",
                  fontWeight: "bold",
                  border: "solid 1px #fff",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  px: 1,
                }}
              />
            ))}
          </Box>
          <Box
            sx={{ height: "auto", overflow: "hidden", borderRadius: "20px" }}
          >
            <Box
              component="img"
              src={imgSrc}
              alt={imgAlt}
              sx={{
                width: "100%",
                height: "200px",
                objectFit: "fill",
                objectPosition: "center",
                borderRadius: "20px",
                border: "dashed 1px #fff",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              m: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textTransform: "capitalize",
                fontWeight: "bold",
                textAlign: "left",
                mb: 0.5,
              }}
            >
              dynamic
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "#ffbd59",
                textAlign: "left",
                mb: 1,
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#aaa", textAlign: "left", mb: 2 }}
            >
              {description}
            </Typography>

            <Box sx={{ alignSelf: "flex-end" }}>
              <ArrowForwardIcon />
            </Box>
          </Box>
        </Paper>
      </NavLink>
    </Container>
  );
};

export default FeatureCard;
